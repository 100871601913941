import {Actions, FinanceData, UserData} from '../actions/UserActions';

export interface IUserState {
  userList: Array<UserData> | null;
  userToken: string | null;
  userHistory: Array<FinanceData> | null;
  userProfile: UserData | null;
  userListGet: boolean;
  error?: string;
  success?: string;
  isAdmin?: boolean;
  userRole?: string;
}

const init: IUserState = {
  userList: null,
  userToken: null,
  userHistory: null,
  userProfile: localStorage.getItem('userProfile')
    ? JSON.parse(localStorage.getItem('userProfile')!)
    : null,
  userListGet: false,
  isAdmin: localStorage.getItem('isAdmin')
    ? JSON.parse(localStorage.getItem('isAdmin')!)
    : undefined,
  error: undefined,
  success: undefined,
  userRole: localStorage.getItem('userRole')
  ? JSON.parse(localStorage.getItem('userRole')!)
  : undefined,
};

export function UserReducer(
  state: IUserState = init,
  action: Actions,
): IUserState {
  let newState = state;
  switch (action.type) {
    case 'LOGIN_ACTION':
      localStorage.setItem('token', action.payload.accessToken);
      localStorage.setItem(
        'userProfile',
        JSON.stringify(action.payload.profile),
      );
      localStorage.setItem(
        'isAdmin',
        JSON.stringify(action.payload.profile.is_admin),
      );
      localStorage.setItem(
        'userRole',
        JSON.stringify(action.payload.profile.role),
      );
      newState = {
        ...state,
        userToken: action.payload.accessToken,
        userProfile: action.payload.profile,
        isAdmin: action.payload.profile.is_admin,
        userRole: action.payload.profile.role,
      };
      break;
    case 'LOGOUT_ACTION':
      localStorage.removeItem('token');
      newState = {
        ...state,
        userToken: null,
        userList: null,
        userHistory: null,
        isAdmin: false,
        userRole: '',
      };
      break;
    case 'USERS_ACTION':
      newState = {...state, userList: action.payload, userListGet: true};
      break;
    case 'USER_ADD_ACTION':
      let newList = state.userList;
      if (newList == null) newList = [];
      newList.push(action.payload);
      newState = {...state, userList: newList};
      break;
    case 'USER_HISTORY':
      newState = {...state, userHistory: action.payload};
      break;
    case 'ERROR_MESSAGE':
      newState = {...state, error: action.payload};
      break;
    case 'SUCCESS_MESSAGE':
      newState = {...state, success: action.payload};
      break;
    case 'CLEAR_MESSAGES':
      newState = {...state, success: undefined, error: undefined};
      break;
    default:
      return state;
  }
  return newState;
}
