import React, {useEffect, useState} from 'react';
import SimpleBar from 'simplebar-react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../api';
import {
  //OptionRates,
  getOptionRates,
} from '../api/actions/OptionRatesActions';
import {
  MainContainer,
  TableContainer,
  Table,
  TableHead,
  TableCellHead,
  TableBody,
  StyledTableRow,
  TableCellHeadCurrency,
  TableCellHeadStrike,
  TableRowHead,
  TableTitleLabel,
  Title,
  TableCellStrike,
  Button,
  TableCell,
  TableTitleLabelExpiry,
} from './styled/styledOptionRatesPage';
import moment from 'moment';

export default function OptionRatesPage() {
  document.title = 'Option Rates';
  const dispatch = useDispatch();
  const optionRates = useSelector(
    (state: RootState) => state.OptionRatesReducer.optionRatesList,
  );

  interface CallPut {
    askPrice: number[];
    bidPrice: number[];
  }

  interface NewRecord {
    strikePrice: number;
    expirationDate: Date;
    call: CallPut;
    put: CallPut;
  }

  const [data, setData] = useState<Array<Array<any>> | undefined>([]);
  const [dataToRender, setDataToRender] = useState<
    Array<Array<any>> | undefined
  >([]);
  const [currency, setCurrency] = useState('BTC');
  const exchangeNames = [
    'Deribit',
    'Bit.com',
    'Delta',
    'PowerTrade',
    'Binance',
    'Okex',
    'ByBit',
  ];

  if (!optionRates)
    dispatch(getOptionRates({baseCurr: currency, addMonths: 1}));

  useEffect(() => {
    dispatch(getOptionRates({baseCurr: currency, addMonths: 1}));
  }, [currency]);

  useEffect(() => {
    setData(optionRates);
  }, [optionRates]);

  useEffect(() => {
    if (data) {
      const dataToRender: React.SetStateAction<any[][] | undefined> = [];
      data.map((month) => {
        let newRecord: NewRecord[] = [];
        month.map((item) => {
          const newRecordIndex = newRecord.findIndex(
            (i) => i.strikePrice === item.strike_price,
          );
          const indexOfExchange = exchangeNames.findIndex(
            (i) => i === item.exchange,
          );
          if (newRecordIndex < 0) {
            if (indexOfExchange >= 0) {
              const askPrice = [];
              askPrice[indexOfExchange] = isNaN(item.ask_price)
                ? 0
                : Number(item.ask_price);
              const bidPrice = [];
              bidPrice[indexOfExchange] = isNaN(item.bid_price)
                ? 0
                : Number(item.bid_price);
              newRecord.push({
                strikePrice: item.strike_price,
                expirationDate: item.date_trunc,
                call: {
                  askPrice: item.type === 'call' ? askPrice : [],
                  bidPrice: item.type === 'call' ? bidPrice : [],
                },
                put: {
                  askPrice: item.type === 'put' ? askPrice : [],
                  bidPrice: item.type === 'put' ? bidPrice : [],
                },
              });
            } else {
              console.log(' nie znaleziono indeksu dla nazwy giełdy');
            }
          } else {
            if (indexOfExchange >= 0) {
              if (item.type === 'call') {
                newRecord[newRecordIndex].call.askPrice[indexOfExchange] =
                  isNaN(item.ask_price) ? 0 : Number(item.ask_price);
                newRecord[newRecordIndex].call.bidPrice[indexOfExchange] =
                  isNaN(item.bid_price) ? 0 : Number(item.bid_price);
              } else if (item.type === 'put') {
                newRecord[newRecordIndex].put.askPrice[indexOfExchange] = isNaN(
                  item.ask_price,
                )
                  ? 0
                  : Number(item.ask_price);
                newRecord[newRecordIndex].put.bidPrice[indexOfExchange] = isNaN(
                  item.bid_price,
                )
                  ? 0
                  : Number(item.bid_price);
              }
            }
          }
        });
        newRecord.sort((a, b) => a.strikePrice - b.strikePrice);
        dataToRender.push(newRecord);
      });
      setDataToRender(dataToRender);
    }
  }, [data]);

  const returnPriceOrSign = (price: number, sign: string = '-') => {
    if (isNaN(price) || Number(price) === 0) return sign;
    const stingNumber = price.toString();
    const indexOfComa = stingNumber.indexOf(',');
    stingNumber.slice(0, indexOfComa + 2);
    return Number(stingNumber).toFixed(2);
  };

  const findMinMax = (array: number[]) => {
    let max: any = array.find((number) => !isNaN(number));
    let min: any = array.find((number) => !isNaN(number));
    array.forEach((number) => {
      if (!isNaN(number) && number !== 0 && number > max) max = number;
      if (!isNaN(number) && number !== 0 && number < min) min = number;
    });
    if (max === 0) max = null;
    if (min === 0) min = null;
    return {max, min};
  };

  return (
    <SimpleBar style={{background: 'rgb(34, 37, 49)', height: '100vh', width: '100%'}}>
      <MainContainer>
        <Title>
          <h3>Option Rates</h3>
          <Button
            color={currency === 'BTC' ? 'yellow' : 'white'}
            onClick={() => setCurrency('BTC')}
          >
            BTC
          </Button>
          <Button
            color={currency === 'ETH' ? 'yellow' : 'white'}
            onClick={() => setCurrency('ETH')}
          >
            ETH{' '}
          </Button>
        </Title>
        {dataToRender
          ? dataToRender.map((month) =>
              month.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRowHead>
                        <TableTitleLabel>CALL</TableTitleLabel>
                        <TableTitleLabelExpiry>
                          <p>EXPIRY</p>
                          <p>{moment(month[0].expirationDate).format('YYYY-MM-DD')}</p>
                        </TableTitleLabelExpiry>
                        <TableTitleLabel>PUT</TableTitleLabel>
                      </TableRowHead>
                      <TableRowHead>
                        <TableCellHead>ByBit</TableCellHead>
                        <TableCellHead>OKX</TableCellHead>
                        <TableCellHead>Binance</TableCellHead>
                        <TableCellHead>PowerTrade</TableCellHead>
                        <TableCellHead>Delta</TableCellHead>
                        <TableCellHead>Bit.com</TableCellHead>
                        <TableCellHead>Deribit</TableCellHead>
                        <TableCellStrike></TableCellStrike>
                        <TableCellHead>Deribit</TableCellHead>
                        <TableCellHead>Bit.com</TableCellHead>
                        <TableCellHead>Delta</TableCellHead>
                        <TableCellHead>PowerTrade</TableCellHead>
                        <TableCellHead>Binance</TableCellHead>
                        <TableCellHead>OKX</TableCellHead>
                        <TableCellHead>ByBit</TableCellHead>
                      </TableRowHead>
                      <TableRowHead className="border">
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadStrike>Strike</TableCellHeadStrike>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                        <TableCellHeadCurrency>BID</TableCellHeadCurrency>
                        <TableCellHeadCurrency>ASK</TableCellHeadCurrency>
                      </TableRowHead>
                    </TableHead>
                    <TableBody>
                      {month.map((row) => (
                        <StyledTableRow>
                          {exchangeNames.map((_, index) => (
                            <>
                              <TableCell
                                color="#ea474c"
                                className={
                                  row.call.bidPrice[
                                    exchangeNames.length - index - 1
                                  ] === findMinMax(row.call.bidPrice).max
                                    ? 'maxBid'
                                    : row.call.bidPrice[
                                        exchangeNames.length - index - 1
                                      ] === findMinMax(row.call.bidPrice).min
                                    ? 'minBid'
                                    : ''
                                }
                              >
                                {returnPriceOrSign(
                                  row.call.bidPrice[
                                    exchangeNames.length - index - 1
                                  ],
                                )}
                              </TableCell>
                              <TableCell color="#06bd86">
                                {returnPriceOrSign(
                                  row.call.askPrice[
                                    exchangeNames.length - index - 1
                                  ],
                                )}
                              </TableCell>
                            </>
                          ))}
                          <TableCellHeadStrike>
                            {Number(row.strikePrice).toFixed(0)}
                          </TableCellHeadStrike>
                          {exchangeNames.map((_, index) => (
                            <>
                              <TableCell
                                color="#ea474c"
                                className={
                                  row.put.bidPrice[index] ===
                                  findMinMax(row.put.bidPrice).max
                                    ? 'maxBid'
                                    : row.put.bidPrice[index] ===
                                      findMinMax(row.put.bidPrice).min
                                    ? 'minBid'
                                    : ''
                                }
                              >
                                {returnPriceOrSign(row.put.bidPrice[index])}
                              </TableCell>
                              <TableCell color="#06bd86">
                                {returnPriceOrSign(row.put.askPrice[index])}
                              </TableCell>
                            </>
                          ))}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null,
            )
          : null}
      </MainContainer>
    </SimpleBar>
  );
}
