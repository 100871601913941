
import { useState, useEffect } from 'react';

function useMediaQuery(query, defoultMatches = window.matchMedia(query)) {
    const [matches, setMatches] = useState(defoultMatches);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) setMatches(media.matches)

        const listener = () => setMatches(media.matches)
        media.addEventListener("change", listener)

        return () => media.removeEventListener("change", listener)
    }, [query, matches])
    return matches
}

export default useMediaQuery;