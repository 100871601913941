import {Actions} from '../actions/OptionRatesActions';


export interface OptionRatesState {
  optionRatesList?: [][];
}

const init: OptionRatesState = {
  optionRatesList: undefined,
};

export function OptionRatesReducer(
  state: OptionRatesState = init,
  action: Actions,
) {
  let newState = state;
  switch (action.type) {
    case 'OPTION_RATES_ACTION':
      newState = {...state, optionRatesList: action.payload};
      break;
    default:
      return state;
  }
  return newState;
}
