import {Actions} from '../actions/FundingRatesActions';
import {FundingRates} from '../actions/FundingRatesActions';

export interface FundingRatesState {
  fundingRatesList: FundingRates[];
}

const init: FundingRatesState = {
  fundingRatesList: [],
};

export function FundingRatesReducer(
  state: FundingRatesState = init,
  action: Actions,
) {
  let newState = state;
  switch (action.type) {
    case 'FUNDING_RATES_ACTION':
      newState = {...state, fundingRatesList: action.payload};
      break;
    default:
      return state;
  }
  return newState;
}
