import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import UserListPage from './pages/UserListPage';
import HistoryPage from './pages/HistoryPage';
import FundingRatesPage from './pages/FundingRatesPage';
import Sidebar, {USER_TYPE} from './pages/components/Sidebar';
import OptionRatesPage from './pages/OptionRatesPage';
import {useSelector} from 'react-redux';
import {RootState} from './api';

const App = () => {
  const userRole = useSelector(
    (state: RootState) => state.UserReducer.userRole,
  );
  const isAdmin = useSelector((state: RootState) => state.UserReducer.isAdmin);

  const routes = [];

  if (userRole) {
    if (USER_TYPE.USER === userRole || isAdmin)
      routes.push(<Route path="/users" exact component={UserListPage} />);
    if ([USER_TYPE.USER].includes(userRole) || isAdmin)
      routes.push(<Route path="/history" exact component={HistoryPage} />);
    if (
      [USER_TYPE.USER, USER_TYPE.FUNDING_RATES_AND_OPTIONS].includes(
        userRole,
      ) ||
      isAdmin
    )
      routes.push(
        <Route path="/funding-rates" exact component={FundingRatesPage} />,
      );
    if (
      [USER_TYPE.USER, USER_TYPE.FUNDING_RATES_AND_OPTIONS].includes(
        userRole,
      ) ||
      isAdmin
    )
      routes.push(
        <Route path="/option-rates" exact component={OptionRatesPage} />,
      );
  }

  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={LoginPage} />
        <Sidebar>{routes.map((route) => route)}</Sidebar>
      </Router>
    </div>
  );
};

export default App;
