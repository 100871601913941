import React, {useEffect, useState} from 'react';
import {
  MainContainer,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  StyledTableRow,
  TableRow,
  TableBody,
  TableCellCurrency,
  TableCellHighlightedGreen,
  TableCellHighlightedRed,
  TableContainerMob,
  TableMob,
  TableHeadMob,
  TableRowMob,
  StyledTableRowMob,
  TableCellMob,
  TableCellMobSpan,
  TableCellHighlightedRedMobile,
  TableCellHighlightedGreenMobile,
  MainContainerMob,
  TableTitle,
} from './styled/styledFundingRatesPage';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../api';
import {
  FundingRates,
  getFundingRates,
} from '../api/actions/FundingRatesActions';
import useMediaQuery from './functions/useMediaQuery';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const FundingRatesPage = () => {
  document.title = 'Funding Rates';
  const matches = useMediaQuery('(min-width: 1200px)');
  const dispatch = useDispatch();
  const fundingRates = useSelector(
    (state: RootState) => state.FundingRatesReducer.fundingRatesList,
  );

  interface Data {
    currency: string;
    exchange: number[];
  }

  const exchangeNames = [
    'Binance',
    'Bit.com',
    'Bitget',
    'BitMEX',
    'ByBit',
    'CoinEx',
    'Huobi',
    'Okex',
  ];

  const indexCurrency = ['BTC', 'ETH', 'ETC'];

  if (fundingRates.length <= 0) dispatch(getFundingRates());

  const [data, setData] = useState<Array<Data>>([]);

  const loadData = (exchange: string, record: FundingRates, symbol: string) => {
    let dataToSet = data;
    let currencyIndex = dataToSet.findIndex(
      (element) => element.currency === symbol,
    );
    let exchangeIndex = exchangeNames.findIndex((e) => e === exchange);
    if (currencyIndex < 0) {
      dataToSet.push({
        currency: symbol,
        exchange: [0, 0, 0, 0, 0, 0, 0],
      });
      dataToSet[dataToSet.length - 1].exchange[exchangeIndex] =
        record.funding_rate * 100;
    } else {
      dataToSet[currencyIndex].exchange[exchangeIndex] =
        record.funding_rate * 100;
    }
    setData(dataToSet);
  };

  useEffect(() => {
    fundingRates.map((record) => {
      const currency = record.ticker;
      const exchange = record.exchange;
      switch (currency) {
        case 'XBTUSD':
        case 'BTC-USD-SWAP':
        case 'BTC-USDT-SWAP':
        case 'BTCUSDT':
        case 'BTC-PERPETUAL':
        case 'BTCUSD_DMCBL':
        case 'BTCUSD':
        case 'BTC-USD-PERPETUAL':
        case 'BTC-USD':
        case 'BTC-USDT':
          loadData(exchange, record, 'BTC');
          break;
        case 'ETHUSD':
        case 'ETH-USD-SWAP':
        case 'ETH-USDT-SWAP':
        case 'ETHUSDT':
        case 'ETH-PERPETUAL':
        case 'ETHUSD_DMCBL':
        case 'ETHUSD':
        case 'ETH-USD-PERPETUAL':
        case 'ETH-USDT':
        case 'ETH-USD':
          loadData(exchange, record, 'ETH');
          break;
        case 'ETC-USD-SWAP':
        case 'ETC-USDT-SWAP':
        case 'ETCUSDT':
        case 'ETCUSD':
        case 'ETC-USDT':
        case 'ETC-USD':
          loadData(exchange, record, 'ETC');
          break;
        default:
          return null;
      }
    });
    data.sort((a, b) => {
      return (
        indexCurrency.indexOf(a.currency) - indexCurrency.indexOf(b.currency)
      );
    });
  }, [fundingRates]);

  const minMaxPrice: {lowest: number; highest: number}[] = [];
  data.map((record) => {
    const lowest = Math.min(...record.exchange);
    const highest = Math.max(...record.exchange);
    minMaxPrice.push({
      lowest,
      highest,
    });
  });
  return (
    <SimpleBar style={{height: '100vh'}}>
      {matches ? (
        <MainContainer>
          <TableContainer>
            <TableTitle>Funding Rates</TableTitle>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Currency</TableCell>
                  {exchangeNames.map((ex) => (
                    <TableCell key={ex}>{ex}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0
                  ? data.map((row, rowIndex) => (
                      <StyledTableRow key={rowIndex}>
                        <TableCellCurrency key={rowIndex}>
                          {row.currency}
                        </TableCellCurrency>
                        {row.exchange.map((price, index) =>
                          price == minMaxPrice[rowIndex].highest ? (
                            <TableCellHighlightedRed key={index}>
                              {price === 0
                                ? '-'
                                : Number(price).toFixed(4) + '%'}
                            </TableCellHighlightedRed>
                          ) : price == minMaxPrice[rowIndex].lowest ? (
                            <TableCellHighlightedGreen key={index}>
                              {price === 0
                                ? '-'
                                : Number(price).toFixed(4) + '%'}
                            </TableCellHighlightedGreen>
                          ) : (
                            <TableCell key={index}>
                              {price === 0
                                ? '-'
                                : Number(price).toFixed(4) + '%'}
                            </TableCell>
                          ),
                        )}
                      </StyledTableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </MainContainer>
      ) : (
        <MainContainerMob>
          <TableContainerMob>
            {exchangeNames.map((ex, index) => (
              <TableMob>
                <TableHeadMob>
                  <TableRowMob>
                    <TableCellMobSpan>{ex}</TableCellMobSpan>
                  </TableRowMob>
                </TableHeadMob>
                <TableBody>
                  <TableRowMob>
                    {data.map((items) => (
                      <TableCellCurrency>{items.currency}</TableCellCurrency>
                    ))}
                  </TableRowMob>
                  <StyledTableRowMob>
                    {data.map((items, currencyIndex) =>
                      items.exchange[index] ==
                      minMaxPrice[currencyIndex].highest ? (
                        <TableCellHighlightedRedMobile key={index}>
                          {items.exchange[index] === 0
                            ? '-'
                            : Number(items.exchange[index]).toFixed(4) + '%'}
                        </TableCellHighlightedRedMobile>
                      ) : items.exchange[index] ==
                        minMaxPrice[currencyIndex].lowest ? (
                        <TableCellHighlightedGreenMobile key={index}>
                          {items.exchange[index] === 0
                            ? '-'
                            : Number(items.exchange[index]).toFixed(4) + '%'}
                        </TableCellHighlightedGreenMobile>
                      ) : (
                        <TableCellMob key={index}>
                          {items.exchange[index] === 0
                            ? '-'
                            : Number(items.exchange[index]).toFixed(4) + '%'}
                        </TableCellMob>
                      ),
                    )}
                  </StyledTableRowMob>
                </TableBody>
              </TableMob>
            ))}
          </TableContainerMob>
        </MainContainerMob>
      )}
    </SimpleBar>
  );
};

export default FundingRatesPage;
