import { Button, createStyles, makeStyles, TextField, Theme, LinearProgress } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import React, { useState } from "react";

export default function AddNewPayment(props: {
    open: boolean, 
    userId: number, 
    handleClose: () => void, 
    addPayment: (payment_date: string, amount_usdt: number, amount_stb: number, stb_price: number, current_stb_price: number, user_id: number, notes: string) => void,
    error: string | undefined,
    success: string | undefined,
    loading: boolean,
  }){

  const classes = useStyles();
  const [paymentDate, setPaymentDate] = useState(moment().format('YYYY-MM-DD'));
  const [amountUsdt, setAmountUsdt] = useState(0);
  const [amountStb, setAmountStb] = useState(0);
  const [stbPrice, setStbPrice] = useState(0);
  const [currentStbPrice, setCurrentStbPrice] = useState(0);
  const [notes, setNotes] = useState('');

  return (
    <div>
      <Modal
        className={classes.modalStyle}
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <form className={classes.formStyle} >
            <TextField
              id="paymentDate"
              label="Payment Date"
              type="date"
              defaultValue={moment().format('YYYY-MM-DD')}
              onChange={(e) => setPaymentDate(e.target.value)}
            />
            <TextField
              id="amountUsdt"
              label="Amount USDT"
              type="number"
              onChange={(e) => setAmountUsdt(Number(e.target.value))}
            />
            <TextField
              id="amountStb"
              label="Amount STB"
              type="number"
              onChange={(e) => setAmountStb(Number(e.target.value))}
            />
            <TextField
              id="stbPrice"
              label="STB Price"
              type="number"
              onChange={(e) => setStbPrice(Number(e.target.value))}
            />
            <TextField
              id="currentStbPrice"
              label="Current STB price"
              type="number"
              onChange={(e) => setCurrentStbPrice(Number(e.target.value))}
            />
            <TextField
              id="notes"
              label="notes"
              type="text"
              onChange={(e) => setNotes(e.target.value)}
            />
            <Button color="primary" onClick={() => props.addPayment(paymentDate, amountUsdt, amountStb, stbPrice, currentStbPrice, props.userId, notes)}>Add new payment</Button>
            {props.loading ? 
              <LinearProgress />
            :null}
            {props.error ? ( 
              <Alert severity="error" >
                {props.error}
              </Alert>
            ): null}
            {props.success ? (
            <Alert severity="success" >
              {props.success}
            </Alert>
            ): null}
          </form>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display:'flex',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      margin:'auto',
      top: '50%',
    },
    modalStyle: {
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    formStyle: {
      display:'flex',
      flexDirection: 'column',
      width:400,
    }
  }),
);