import React, {useEffect} from 'react';
import {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getUsers, logout} from '../../api/actions/UserActions';
import {RootState} from '../../api';

import {NavLink} from 'react-router-dom';
import GroupIcon from '@material-ui/icons/Group';
import HistoryIcon from '@material-ui/icons/History';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import {
  Container,
  SidebarContainer,
  TopSection,
  Bars,
  Icon,
  LinkText,
  Main,
  Logo,
  ButtonLogout,
  SidebarContainerMobile,
} from '../styled/styledSidebar';
import '../styled/styledSidebar.css';
import useMediaQuery from './../functions/useMediaQuery';

type Props = {
  children: JSX.Element[];
};

export const USER_TYPE = {
  USER: 'USER',
  FUNDING_RATES: 'FUNDING_RATES',
  FUNDING_RATES_AND_OPTIONS: 'FUNDING_RATES_AND_OPTIONS',
};

export default function Sidebar({children}: Props) {
  const matches = useMediaQuery('(min-width: 600px)');

  const menuItem = [
    {
      path: '/users',
      name: 'Users list',
      icon: <GroupIcon />,
      adminOnly: true,
      roles: [],
    },
    {
      path: '/history',
      name: 'History',
      icon: <HistoryIcon />,
      adminOnly: false,
      roles: [USER_TYPE.USER],
    },
    {
      path: '/funding-rates',
      name: 'Funding rates',
      icon: <MonetizationOnIcon />,
      adminOnly: true,
      roles: [USER_TYPE.FUNDING_RATES_AND_OPTIONS],
    },
    {
      path: '/option-rates',
      name: 'Option rates',
      icon: <LocalAtmIcon />,
      adminOnly: true,
      roles: [USER_TYPE.FUNDING_RATES_AND_OPTIONS],
    },
  ];

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const usersData = useSelector(
    (state: RootState) => state.UserReducer.userList,
  );
  const isAdmin = useSelector((state: RootState) => state.UserReducer.isAdmin);
  const userRole = useSelector(
    (state: RootState) => state.UserReducer.userRole,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (usersData == null) dispatch(getUsers());
  });

  useEffect(() => {
    if (matches) setIsOpen(false);
  }, []);

  const logoutBtn = (e: any) => {
    e.preventDefault();
    dispatch(logout());
    history.push('/');
  };
  return (
    <Container>
      {matches ? (
        <SidebarContainer width={isOpen ? '300px' : '50px'}>
          <TopSection>
            <Logo display={isOpen ? 'block' : 'none'}>Stable</Logo>
            <Bars>
              <MenuIcon onClick={toggle} />
            </Bars>
          </TopSection>
          {menuItem.map((item, index) =>
            (item.adminOnly && isAdmin) ||
            (userRole && item.roles.includes(userRole)) ? (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeClassName="active"
              >
                <Icon>{item.icon}</Icon>
                <LinkText display={isOpen ? 'block' : 'none'}>
                  {item.name}
                </LinkText>
              </NavLink>
            ) : null,
          )}
          <ButtonLogout onClick={logoutBtn}>
            <Icon>
              <ArrowBackIcon />
            </Icon>
            <LinkText display={isOpen ? 'block' : 'none'}>Logout</LinkText>
          </ButtonLogout>
        </SidebarContainer>
      ) : (
        <SidebarContainerMobile width={isOpen ? '150px' : '50px'}>
          <TopSection>
            <Logo display={isOpen ? 'block' : 'none'}>Stable</Logo>
            <Bars>
              <MenuIcon onClick={toggle} />
            </Bars>
          </TopSection>
          {menuItem.map((item, index) =>
            (item.adminOnly && isAdmin) ||
            (userRole && item.roles.includes(userRole)) ? (
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeClassName="active"
              >
                <Icon>{item.icon}</Icon>
                <LinkText display={isOpen ? 'block' : 'none'}>
                  {item.name}
                </LinkText>
              </NavLink>
            ) : null,
          )}
          <ButtonLogout onClick={logoutBtn}>
            <Icon>
              <ArrowBackIcon />
            </Icon>
            <LinkText display={isOpen ? 'block' : 'none'}>Logout</LinkText>
          </ButtonLogout>
        </SidebarContainerMobile>
      )}

      <Main>{children}</Main>
    </Container>
  );
}
