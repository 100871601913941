import {apiConfig} from '../config';

class ApiCall {
  makeJsonApiCall<T>(
    method: 'POST' | 'GET' | 'PUT',
    path: string,
    data: any = null,
    withToken: boolean = true,
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      if (typeof data === 'object') {
        data = JSON.stringify(data);
      }
      let request = new XMLHttpRequest();
      request.open(method, apiConfig.url + path, true);
      let token = localStorage.getItem('token');
      console.log(`request: ${method} ${path} ${data} ${token}`);
      if (withToken && token != null) {
        request.setRequestHeader('x-access-token', `${token}`);
      }
      request.setRequestHeader('Content-type', 'application/json');
      request.addEventListener('load', () => {
        let response = JSON.parse(request.response);
        if (request.status === 200) {
          if (response.success !== undefined && response.success) {
            resolve(response);
          } else {
            reject(response.message);
          }
        } else if (request.status === 401) {
          refreshToken()
            .then(() => {
              resolve(this.makeJsonApiCall<T>(method, path, data, withToken));
            })
            .catch(reject);
        } else {
          try {
            reject(response);
          } catch (err) {
            reject(err);
          }
        }
      });
      request.addEventListener(
        'error',
        (reason) => {
          console.log(reason);
          reject(reason);
        },
        false,
      );
      if (method == 'GET') {
        request.send();
      } else {
        request.send(data);
      }
    });
  }
}

function refreshToken() {
  return new Promise((resolve, reject) => {
    let request = new XMLHttpRequest();
    let token = localStorage.getItem('token');
    if (token == null) reject();
    request.open('GET', apiConfig.url + '/users/refreshToken');
    request.setRequestHeader('x-access-token', `${token}`);
    request.setRequestHeader('Content-type', 'application/json');
    request.addEventListener('load', () => {
      let response = JSON.parse(request.response);
      if (request.status === 200 && response.success) {
        localStorage.setItem('token', response.token);
        resolve(1);
      } else if (!response.success) {
        reject();
      }
    });
    request.addEventListener(
      'error',
      (reason) => {
        console.log(reason);
        reject(reason);
      },
      false,
    );
    request.send();
  });
}

let apiCall = new ApiCall();
export {apiCall};
