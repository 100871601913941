import React, {useEffect} from 'react';
import {createStyles} from '@material-ui/core/styles';
import {TextField, Button, withStyles, Box} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from '../api/actions/UserActions';
import {RootState} from '../api';
import {USER_TYPE} from './components/Sidebar';

interface ILoginPageProps {
  classes?: any;
}

const LoginPage = (props: ILoginPageProps) => {
  document.title = 'STABLE';
  const history = useHistory();
  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');

  const isLoggedIn = useSelector((state: RootState) => {
    return state.UserReducer.userToken != null;
  });
  const isAdmin = useSelector((state: RootState) => {
    return (
      state.UserReducer.userProfile != null &&
      state.UserReducer.userProfile.is_admin
    );
  });
  const userRole = useSelector(
    (state: RootState) => state.UserReducer.userRole,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && isAdmin) {
      history.push('/users');
    } else if (isLoggedIn) {
      if (userRole === USER_TYPE.FUNDING_RATES_AND_OPTIONS) {
        history.push('/funding-rates');
      } else history.push('/history');
    }
  });
  function handleClick(e: any) {
    e.preventDefault();
    dispatch(loginUser({login: login, password: password}));
  }

  function onDataChanged(e: any, field: string) {
    if (field === 'login') {
      setLogin(e.target.value);
    }
    if (field === 'password') {
      setPassword(e.target.value);
    }
  }

  return (
    <Box
      maxWidth="xs"
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <div className={props.classes.root}>
        <form noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="login"
            label="Login"
            name="login"
            autoComplete="login"
            autoFocus
            onChange={(e) => onDataChanged(e, 'login')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            autoComplete="password"
            autoFocus
            onChange={(e) => onDataChanged(e, 'password')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={props.classes.submit}
            onClick={handleClick}
          >
            Sign in
          </Button>
        </form>
      </div>
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: 'normal',
  },
});

export default withStyles(styles as any)(LoginPage) as any;
