import {Dispatch} from '@reduxjs/toolkit';
import {apiCall} from '../ApiCall';

export type FundingRates = {
  id: number;
  created_at: string;
  exchange: string;
  ticker: string;
  funding_rate: number;
  fundingtime: string;
};

type FundingRatesResponse = {
  fundingRates: Array<FundingRates>;
};

type fundingRatesAction = {
  readonly type: 'FUNDING_RATES_ACTION';
  readonly payload: FundingRates[];
};

export const getFundingRates =
  () => async (dispatch: Dispatch<fundingRatesAction>) => {
    apiCall
      .makeJsonApiCall<FundingRatesResponse>(
        'GET',
        '/fundingRates/funding-rates',
      )
      .then((response) => {
        dispatch({
          type: 'FUNDING_RATES_ACTION',
          payload: response.fundingRates,
        });
      });
  };

export type Actions = fundingRatesAction;
