import React, {useEffect, useState} from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  Container,
  TableBody,
  IconButton,
  makeStyles,
  Collapse,
  Box,
  Button,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../api';
import {
  addNewUser,
  clearMessages,
  disableUser,
  editUser,
  FinanceData,
  getUsers,
} from '../api/actions/UserActions';
import moment from 'moment';
import AddNewUserModal from './modals/AddNewUserModal';
import AddNewPayment from './modals/AddNewPayment';
import {addPayment, editPaymentApi} from '../api/actions/PaymentActions';
import EditPayment from './modals/EditPayment';
import Alert from '@material-ui/lab/Alert';
import EditUserModal from './modals/EditUserModal';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

function createData(
  id: number,
  login: string,
  name: string,
  finances: FinanceData[],
) {
  return {
    id,
    login,
    name,
    finances,
  };
}

const ExpandableRow = (props: {row: ReturnType<typeof createData>}) => {
  document.title = 'Users List';
  const [open, setOpen] = useState(false);
  const [openNewPayment, setOpenNewPayment] = useState(false);
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const [addNewPaymentError, setAddNewPaymentError] = useState<
    string | undefined
  >(undefined);
  const [addNewPaymentSuccess, setAddNewPaymentSuccess] = useState<
    string | undefined
  >(undefined);
  const [addNewPaymentLoading, setAddNewPaymentLoading] = useState(false);
  const [editPaymentError, setEditPaymentError] = useState<string | undefined>(
    undefined,
  );
  const [editPaymentSuccess, setEditPaymentSuccess] = useState<
    string | undefined
  >(undefined);
  const [editPaymentLoading, setEditPaymentLoading] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);

  const [dataToEdit, setDataToEdit] = useState({
    payment_date: '',
    amount_usdt: -1,
    amount_stb: -1,
    stb_price: -1,
    current_stb_price: -1,
    user_id: -1,
    id: -1,
    payout_date: '',
    type: '',
    notes: '',
  });

  const dispatch = useDispatch();
  const {row} = props;
  const classes = useRowStyles();

  function createPayment(
    payment_date: string,
    amount_usdt: number,
    amount_stb: number,
    stb_price: number,
    current_stb_price: number,
    user_id: number,
    notes: string,
  ) {
    setAddNewPaymentLoading(true);
    dispatch(
      addPayment(
        {
          paymentDate: payment_date,
          amount_usdt: amount_usdt,
          amount_stb: amount_stb,
          stb_price: stb_price,
          current_stb_price: current_stb_price,
          user_id: user_id,
          notes: notes,
        },
        (err?: string) => {
          if (!err) {
            setAddNewPaymentSuccess('Payment has been added.');
            setTimeout(function () {
              handleAddNewPaymentClose();
              dispatch(getUsers());
            }, 750);
          } else {
            setAddNewPaymentLoading(false);
            setAddNewPaymentError(err);
          }
        },
      ),
    );
  }

  function editPayment(
    payment_date: string | undefined,
    amount_usdt: number | undefined,
    amount_stb: number | undefined,
    stb_price: number | undefined,
    current_stb_price: number | undefined,
    id: number,
    notes: string | undefined,
  ) {
    let changedData: any = {};
    changedData.finance_id = id;
    let valid = false;
    if (payment_date != undefined) {
      changedData.payment_date = payment_date;
      valid = true;
    }
    if (amount_usdt != undefined) {
      changedData.amount_usdt = amount_usdt;
      valid = true;
    }
    if (amount_stb != undefined) {
      changedData.amount_stb = amount_stb;
      valid = true;
    }
    if (stb_price != undefined) {
      changedData.stb_price = stb_price;
      valid = true;
    }
    if (current_stb_price != undefined) {
      changedData.current_stb_price = current_stb_price;
      valid = true;
    }
    if (notes != undefined) {
      changedData.notes = notes;
      valid = true;
    }
    if (!valid) {
      return setEditPaymentError('No changes.');
    }
    setEditPaymentLoading(true);
    dispatch(
      editPaymentApi(changedData, (err?: string) => {
        if (!err) {
          setEditPaymentSuccess('Payment changes saved.');
          setTimeout(function () {
            handleEditPaymentClose();
            dispatch(getUsers());
          }, 750);
        } else {
          setEditPaymentLoading(false);
          setEditPaymentError(err);
        }
      }),
    );
  }

  function openModalNewPayment() {
    setOpenNewPayment(true);
  }

  function handleAddNewPaymentClose() {
    setAddNewPaymentSuccess(undefined);
    setAddNewPaymentError(undefined);
    setAddNewPaymentLoading(false);
    setOpenNewPayment(false);
  }

  function handleEditPaymentClose() {
    setEditPaymentSuccess(undefined);
    setEditPaymentError(undefined);
    setEditPaymentLoading(false);
    setOpenEditPayment(false);
  }

  function openModalEditPayment(finance: FinanceData) {
    setDataToEdit({...finance});
    setOpenEditPayment(true);
  }

  function disableUserFn(userId: number) {
    dispatch(
      disableUser(userId, () => {
        dispatch(getUsers());
      }),
    );
  }

  function editUserFn(
    userId: number,
    login?: string,
    name?: string,
    password?: string,
  ) {
    console.log(name);
    dispatch(
      editUser(
        {id: userId, login: login, name: name, password: password},
        () => {
          dispatch(getUsers());
          setOpenEditUser(false);
        },
      ),
    );
  }

  return (
    <React.Fragment>
      <EditUserModal
        name={row.name}
        login={row.login}
        userId={row.id}
        open={openEditUser}
        handleClose={() => setOpenEditUser(false)}
        editUser={editUserFn}
      />
      <AddNewPayment
        open={openNewPayment}
        handleClose={() => handleAddNewPaymentClose()}
        addPayment={createPayment}
        userId={row.id}
        error={addNewPaymentError}
        success={addNewPaymentSuccess}
        loading={addNewPaymentLoading}
      />
      <EditPayment
        open={openEditPayment}
        handleClose={() => handleEditPaymentClose()}
        editPayment={editPayment}
        userId={row.id}
        data={dataToEdit}
        error={editPaymentError}
        success={editPaymentSuccess}
        loading={editPaymentLoading}
      />
      <TableRow className={classes.root} key={row.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.login}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <Button color="primary" onClick={() => openModalNewPayment()}>
            add payment
          </Button>
          <Button color="primary" onClick={() => setOpenEditUser(true)}>
            edit user
          </Button>
          <Button color="primary" onClick={() => disableUserFn(row.id)}>
            disable user
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="medium" aria-label="payments">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Finance Id</TableCell>
                    <TableCell>Transaction date</TableCell>
                    <TableCell>USDT amount</TableCell>
                    <TableCell>STB amount</TableCell>
                    <TableCell>1 STB price</TableCell>
                    <TableCell>Current date</TableCell>
                    <TableCell>Current STB price</TableCell>
                    <TableCell>Current USDT balance</TableCell>
                    <TableCell>USDT P&amp;L</TableCell>
                    <TableCell>Rate of return</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.finances.map((f) => (
                    <TableRow key={f.id}>
                      <TableCell></TableCell>
                      <TableCell>{f.id}</TableCell>
                      <TableCell>{f.payment_date}</TableCell>
                      <TableCell>{Number(f.amount_usdt).toFixed(2)}</TableCell>
                      <TableCell>{Number(f.amount_stb).toFixed(2)}</TableCell>
                      <TableCell>{Number(f.stb_price).toFixed(2)}</TableCell>
                      <TableCell>{moment().format('YYYY-MM-DD')}</TableCell>
                      <TableCell>
                        {Number(f.current_stb_price).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {Number(f.amount_stb * f.current_stb_price).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {Number(
                          f.amount_stb * f.current_stb_price - f.amount_usdt,
                        ).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {Number(
                          ((f.amount_stb * f.current_stb_price -
                            f.amount_usdt) /
                            f.amount_usdt) *
                            100,
                        ).toFixed(2) + '%'}
                      </TableCell>
                      <TableCell>{f.notes}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={() => openModalEditPayment(f)}
                        >
                          edit payment
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const UserListPage = () => {
  const [openAddUser, setOpenAddUser] = useState(false);

  useEffect(() => {
    if (usersData == null) dispatch(getUsers());
  });

  const classes = useRowStyles();
  const dispatch = useDispatch();
  const usersData = useSelector(
    (state: RootState) => state.UserReducer.userList,
  );
  const successMessage = useSelector(
    (state: RootState) => state.UserReducer.success,
  );
  const errorMessage = useSelector(
    (state: RootState) => state.UserReducer.error,
  );

  function openModalAddUser() {
    setOpenAddUser(true);
  }

  function createNewUser(login: string, name: string, password: string) {
    dispatch(addNewUser({login: login, name: name, password: password}));
    dispatch(getUsers());
    setOpenAddUser(false);
  }

  return (
    <SimpleBar style={{height: '100vh'}}>
      <Container component="main">
        <AddNewUserModal
          open={openAddUser}
          handleClose={() => setOpenAddUser(false)}
          addUser={createNewUser}
        />
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Id</TableCell>
              <TableCell>Login</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>
                <Button color="primary" onClick={() => openModalAddUser()}>
                  Add Investor
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersData == null
              ? null
              : usersData.map((row) => (
                  <ExpandableRow row={row} key={row.login} />
                ))}
          </TableBody>
        </Table>
        {successMessage ? (
          <Alert
            onClose={() => {
              dispatch(clearMessages());
            }}
            className={classes.alertStyle}
            severity="success"
          >
            {successMessage}
          </Alert>
        ) : null}
        {errorMessage ? (
          <Alert
            onClose={() => {
              dispatch(clearMessages());
            }}
            className={classes.alertStyle}
            severity="error"
          >
            {errorMessage}
          </Alert>
        ) : null}
      </Container>
    </SimpleBar>
  );
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  fabStyle: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  alertStyle: {
    margin: 0,
    top: 'auto',
    width: '60%',
    right: 'auto',
    bottom: 0,
    left: 'auto',
    position: 'fixed',
  },
  textFieldStyle: {
    width: 80,
  },
});

export default UserListPage;
