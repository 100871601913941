import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: rgb(34, 37, 49);
  flex-direction: column;
`;

export const Title = styled.div`
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Table = styled.table`
  border-collapse: collapse;
  background-color: #333333f0;
  width: 99%;
`;

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const Button = styled.div`
  color: ${(props) => (props.color ? props.color : 'white')};
  font-weight: bold;
  font-size: 1.1rem;
  padding: 5px 20px;
  margin: 5px 15px;
  background-color: #333333f0;
  border-radius: 2px;
  cursor: pointer;
`;

export const TableTitleLabel = styled.th.attrs({colSpan: 14})`
  color: white;
  font-weight: bold;
  font-size: 0.9rem;
  flex: 1 1 100%;
`;
export const TableTitleLabelExpiry = styled.th`
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  flex: 1 1 100%;

  & p{
    margin: 2px 0;
  }
`;

export const TableHead = styled.thead`
  background-color: #333333;
  color: #fff;
  font-weight: bold;
  position: sticky;
  top: 0;
`;

export const TableRowHead = styled.tr`
  border-bottom: ${(props) =>
    props.className === 'border' ? '1px solid #ddddddae' : ''};
`;

export const StyledTableRow = styled.tr`
  border-bottom: 1px solid #dddddd58;
`;

export const TableRow = styled.tr`
  //
`;

export const TableCell = styled.th`
  color: ${(props) => (props.color ? props.color : 'white')};
  padding-right: 5px;
  font-weight: normal;
  font-size: 0.8rem;
  text-align: right;
  background-color: ${(props) =>
    props.className === 'maxBid'
      ? '#1adb002f'
      : props.className === 'minBid'
      ? '#a100002f'
      : ''};
  color: ${(props) =>
    props.className === 'maxBid'
      ? '#d6d6d6'
      : props.className === 'minBid'
      ? '#ffffff'
      : ''};
`;
export const TableCellStrike = styled.th`
  color: white;
`;
export const TableCellHead = styled.th.attrs({colSpan: 2})`
  font-size: 0.9rem;
  color: white;
`;
export const TableCellHeadCurrency = styled.th`
  font-size: 0.9rem;
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : 'white')};
`;
export const TableCellHeadStrike = styled.th`
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  color: white;
  background-color: #2b2b2b;
`;

export const TableCellHighlightedRed = styled.th`
  padding: 12px 15px;
  color: indianred;
`;
export const TableCellHighlightedGreen = styled.th`
  padding: 12px 15px;
  color: lightgreen;
`;

export const TableBody = styled.tbody``;
