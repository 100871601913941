import { Button, createStyles, makeStyles, TextField, Theme } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";

export default function AddNewUserModal(props: {open: boolean, handleClose: () => void, addUser: (login: string, name: string, password: string) => void}){
  const classes = useStyles();
  const [login, setLogin] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  // function onChangeFiled(e : React.ChangeEvent<HTMLInputElement>){
  //   console.log(e.target.value);
  //   console.log(e.target.id)
  // }

  return (
    <div>
      <Modal
        className={classes.modalStyle}
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <form className={classes.formStyle} >
            <TextField
              id="login"
              label="Login"
              onChange={(e) => setLogin(e.target.value)}
            />
            <TextField
              id="name"
              label="Name"
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button color="primary" onClick={() => props.addUser(login, name, password)}>save</Button>
          </form>
        </div>
      </Modal>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display:'flex',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      margin:'auto',
      top: '50%',
    },
    modalStyle: {
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    formStyle: {
      display:'flex',
      flexDirection: 'column',
      width:400,
    }
  }),
);