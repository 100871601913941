import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

interface SidebarContainerProps {
  width: string;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  background-color: rgb(30, 28, 48);
  color: #333333;
  height: 100vh;
  width: ${(props) => props.width};
`;

export const SidebarContainerMobile = styled.div<SidebarContainerProps>`
  z-index: 100;
  background-color: rgb(30, 28, 48);
  color: #333333;
  width: ${(props) => props.width};
  position: ${(props) => (props.width === '150px' ? 'absolute' : 'static')};
  top: 0;
  left: 0;
  bottom: 0;
  right: calc(100vw - ${(props) => props.width});
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px;
`;
export const Bars = styled.div`
  display: flex;
  font-size: 25px;
  margin-left: auto;
  margin-right: 15px;
  color: white;
`;

interface LogoProps {
  display: string;
}
export const Logo = styled.div<LogoProps>`
  font-size: 25px;
  color: darkgrey;
  display: ${(props) => props.display};
`;

export const Icon = styled.div`
  font-size: 20px;
`;

interface LinkTextProps {
  display: string;
}
export const LinkText = styled.div<LinkTextProps>`
  font-size: 20px;
  display: ${(props) => props.display};
`;
export const Main = styled.div`
  width: 100%;
`;

export const ButtonLogout = styled.button`
  display: flex;
  color: white;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  border: none;
  background: transparent;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: lightslategray;
    color: black;
    transition: all 0.5s;
  }

  &active {
    background: lightslategray;
    color: black;
  }
`;
