import React, {useEffect} from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  Container,
  TableBody,
  makeStyles,
  Box,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../api';
import {getUserHistory} from '../api/actions/UserActions';
import moment from 'moment';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const HistoryPage = () => {
  document.title = 'History';
  useEffect(() => {
    console.log(userHistory);
    if (userHistory == null) dispatch(getUserHistory());
  });

  const classes = useRowStyles();
  const dispatch = useDispatch();
  const userHistory = useSelector(
    (state: RootState) => state.UserReducer.userHistory,
  );

  return (
    <SimpleBar style={{height: '100vh'}}>
      <Container component="main">
        <React.Fragment>
          <Box margin={1}>
            <Table size="medium" aria-label="payments">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Finance Id</TableCell>
                  <TableCell className={classes.noWrap}>
                    Transaction date
                  </TableCell>
                  <TableCell>USDT amount</TableCell>
                  <TableCell>STB amount</TableCell>
                  <TableCell>1 STB price</TableCell>
                  <TableCell className={classes.noWrap}>Current date</TableCell>
                  <TableCell>Current STB price</TableCell>
                  <TableCell>USDT price</TableCell>
                  <TableCell>USDT P&amp;L</TableCell>
                  <TableCell>Rate of return</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userHistory == null
                  ? null
                  : userHistory.map((f) => (
                      <TableRow key={f.id}>
                        <TableCell size="small"></TableCell>
                        <TableCell>{f.id}</TableCell>
                        <TableCell>{f.payment_date}</TableCell>
                        <TableCell>
                          {Number(f.amount_usdt).toFixed(2)}
                        </TableCell>
                        <TableCell>{Number(f.amount_stb).toFixed(2)}</TableCell>
                        <TableCell>{Number(f.stb_price).toFixed(2)}</TableCell>
                        <TableCell>{moment().format('YYYY-MM-DD')}</TableCell>
                        <TableCell>
                          {Number(f.current_stb_price).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(f.amount_stb * f.current_stb_price).toFixed(
                            2,
                          )}
                        </TableCell>
                        <TableCell>
                          {Number(
                            f.amount_stb * f.current_stb_price - f.amount_usdt,
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {Number(
                            ((f.amount_stb * f.current_stb_price -
                              f.amount_usdt) /
                              f.amount_usdt) *
                              100,
                          ).toFixed(2) + '%'}
                        </TableCell>
                      </TableRow>
                    ))}
                <TableRow key={'summary'}>
                  <TableCell>Summary</TableCell>
                  <TableCell>USDT</TableCell>
                  <TableCell>
                    <b>
                      {userHistory == null || userHistory.length == 0
                        ? 0
                        : userHistory
                            .map((f) => Number(f.amount_usdt))
                            .reduce((f, p) => f + p)
                            .toFixed(2)}
                    </b>
                  </TableCell>
                </TableRow>
                <TableRow key={'summary'}>
                  <TableCell></TableCell>
                  <TableCell>sum (stb * stb price)</TableCell>
                  <TableCell>
                    <b>
                      {userHistory == null || userHistory.length == 0
                        ? 0
                        : userHistory
                            .map((f) => {
                              console.log(
                                `${Number(f.amount_stb)} * ${Number(
                                  f.current_stb_price,
                                )}`,
                              );
                              return (
                                Number(f.amount_stb) *
                                Number(f.current_stb_price)
                              );
                            })
                            .reduce((f, p) => {
                              console.log(`${f} + ${p}`);
                              return f + p;
                            })
                            .toFixed(2)}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </React.Fragment>
      </Container>
    </SimpleBar>
  );
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  fabStyle: {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  textFieldStyle: {
    width: 80,
  },
  noWrap: {
    'white-space': 'noWrap',
  },
});

export default HistoryPage;
