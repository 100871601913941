import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: rgb(34, 37, 49);
`;

export const TableTitle = styled.div`
  color: white;
  width: 100%;
  margin: 25px 0px;
  font-weight: bold;
  font-size: 1.3rem;
`;

export const TableContainer = styled.div`
  display: flex;
  width: 80%;
  height: 15%;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const Table = styled.table`
  border-collapse: collapse;
  min-width: 400px;
  background-color: #333333f0;
`;

export const TableHead = styled.thead`
  background-color: #333333;
  color: #fff;
  font-weight: bold;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #dddddd;
`;

export const TableCell = styled.th`
  padding: 12px 15px;
  color: white;
`;

export const TableCellHighlightedRed = styled.th`
  padding: 12px 15px;
  color: indianred;
`;
export const TableCellHighlightedGreen = styled.th`
  padding: 12px 15px;
  color: lightgreen;
`;

export const TableBody = styled.tbody``;
export const StyledTableRow = styled.tr`
  border-bottom: 1px solid #ddddddae;
`;

// ------ Mobile styled ------

export const MainContainerMob = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(34, 37, 49);
`;

export const TableContainerMob = styled.div`
  display: flex;
  width: 100%;
  height: 15%;
  flex-direction: column;
`;

export const TableMob = styled.table`
  border-collapse: collapse;
  min-width: 280px;
  background-color: #333333f0;
  margin-bottom: 15px;
  border-collapse: collapse;
`;

export const TableHeadMob = styled.thead`
  background-color: #1f1f1f;
  color: #fff;
  font-weight: bold;
`;

export const TableRowMob = styled.tr`
  border-bottom: 1px solid #dddddd;
  position: relative;
`;

export const StyledTableRowMob = styled.tr`
  border-bottom: 1px solid #ddddddae;
`;

export const TableCellMob = styled.th`
  padding: 8px 5px;
  color: white;
  font-size: 0.9em;
`;
export const TableCellMobSpan = styled.th.attrs({colSpan: 3})`
  padding: 8px 5px;
  color: white;
`;

export const TableCellCurrency = styled.th`
  padding: 8px 5px;
  color: #ffff30;
`;

export const TableCellHighlightedRedMobile = styled.th`
  padding: 8px 5px;
  color: indianred;
`;
export const TableCellHighlightedGreenMobile = styled.th`
  padding: 8px 5px;
  color: lightgreen;
`;
