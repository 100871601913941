import {combineReducers} from '@reduxjs/toolkit';
import {UserReducer} from './reducers/UserReducer';
import {FundingRatesReducer} from './reducers/FundingRatesReducer';
import {OptionRatesReducer} from './reducers/OptionRatesReducer';

export const rootReducer = combineReducers({
  UserReducer,
  FundingRatesReducer,
  OptionRatesReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
